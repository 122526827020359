import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useHelperFunctions from "../../helpers/functions";

const ProtectedRoute = ({ children }) => {
  const { isUser, verifyUser } = useHelperFunctions();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    verifyUser();
    setIsLoading(false); // Mark loading as complete
  }, [isUser]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {children}
      <Outlet />
    </div>
  );
};

export default ProtectedRoute;
