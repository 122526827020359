import React, { useState } from "react";
import {
  Box,
  MenuItem,
  Typography,
  Select,
  InputAdornment,
  OutlinedInput,
  Menu,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SectionSubHeader from "../../../shared/typography/SectionSubHeader";

const DropdownWithSearch = ({
  options,
  onSelect,
  setCustomer,
  selectedOption,
  setSelectedOption,
  customer,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  // Filter options based on search query
  const filteredOptions = options?.data.filter(
    (option) =>
      option.customerId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option.customerName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery(""); // Reset search query when closing dropdown
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelect = (customerId) => {
    const selected = options?.data.find(
      (option) => option.customerId === customerId
    );
    setCustomer(selected);
    setSelectedOption(customerId);
    setAnchorEl(null); // Close the dropdown
    if (onSelect) onSelect(selected); // Pass the selected object to the parent
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SectionSubHeader>Select Customer</SectionSubHeader>

      {/* Dropdown Trigger */}
      <Select
        fullWidth
        displayEmpty
        value={selectedOption}
        onClick={handleOpen}
        renderValue={(selected) => {
          if (!selected) return "Select a customer";
          const selectedItem = options?.data.find(
            (option) => option.customerId === selected
          );
          return `${selectedItem.customerName} (${selectedItem.customerId})`;
        }}
        input={<OutlinedInput />}
        readOnly // Disable default dropdown behavior
      />

      {/* Custom Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
            overflow: "auto", // Enable scrolling for options
          },
        }}
      >
        {/* Static Search Bar */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            p: 1,
            borderBottom: "1px solid #ccc",
          }}
        >
          <OutlinedInput
            fullWidth
            placeholder="Search by Name or ID"
            value={searchQuery}
            onChange={handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            sx={{
              fontSize: "1rem", // Ensure text is visible
              color: "#000", // Explicitly set text color to black
              backgroundColor: "white", // Ensure background contrast
              borderRadius: "4px", // Match default Material-UI style
            }}
          />
        </Box>

        {/* Options */}
        {filteredOptions?.map((option) => (
          <MenuItem
            key={option.customerId}
            onClick={() => handleSelect(option.customerId)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography>{option.customerName}</Typography>
              <Typography>{option.customerId}</Typography>
            </Box>
          </MenuItem>
        ))}

        {/* No Results Found */}
        {filteredOptions?.length === 0 && (
          <MenuItem disabled>
            <Typography>No results found</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default DropdownWithSearch;
