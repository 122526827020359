import {
  Settings,
  Person,
  Description,
  Assignment,
  AddBox,
  FolderOpen,
} from "@mui/icons-material";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import BusinessIcon from "@mui/icons-material/Business";

export const menuItems = [
  {
    label: "Current Order",
    path: "/order-dashboard",
    icon: <QrCode2OutlinedIcon />,
  },
  {
    label: "Order",
    icon: <Assignment />,
    children: [
      { label: "Browse Orders", path: "/all-orders", icon: <FolderOpen /> },
      { label: "Create New Order", path: "/create-order", icon: <AddBox /> },
    ],
  },
  {
    label: "Customers",
    icon: <BusinessIcon />,
    children: [
      {
        label: "View All Customers",
        path: "/all-customers",
        icon: <FolderOpen />,
      },
      {
        label: "Add New Customer",
        path: "/add-customer",
        icon: <AddBox />,
      },
    ],
  },
  {
    label: "Reports",
    icon: <Description />,
    children: [
      {
        label: "Download Report",
        path: "/reports",
        icon: <FolderOpen />,
      },
    ],
  },

  {
    label: "Settings",
    path: "/settings",
    icon: <Settings />,
  },
  {
    label: "Profile",
    path: "/user",
    icon: <Person />,
  },
];

/**
 * Order
 * - create new order -> navigates to /create-order
 * - browse orders -> navigates to /all-orders
 * Reports
 * - edit report -> navigates to /:orderId || 123
 * - download report -> navigates to /orderDashboard (show available reports, as well as media and communication details)
 * Settings -> navigates to /settings
 * Profile -> navigates to /user
 */
