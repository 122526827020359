import React, { useState, useRef, useEffect } from "react";
import useHelperFunctions from "../../helpers/functions";
import "./report.css";
import SectionHeader from "../shared/typography/SectionHeader";
import SectionSubHeader from "../shared/typography/SectionSubHeader";
import { Button, Menu, MenuItem, CircularProgress } from "@mui/material";
import ReportFieldContainer from "./components/ReportFieldContainer";
import GroupedReportFields from "../shared/renders/GroupedReportFields";
import { useFetchData } from "../../api/axios";
import { reportData } from "../../config/order/order";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { lookup } from "../../config/lookup/lookup";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ReportPage() {
  const { navigate, showOutlet, currentOrder } = useHelperFunctions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state for download button
  const reportRef = useRef(null);
  let location = useLocation();
  let currentReport = location.pathname.split("/")[1];

  const handleSwitchReportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuSelect = (report) => {
    setAnchorEl(null);
  };

  function handleOpenReport(report) {
    console.log(currentOrder);
    navigate(`/${currentOrder["orderId"]}`);
    handleMenuSelect();
  }

  function handleOpenField(data, state) {
    navigate(data, { state });
    showOutlet();
  }

  const { data: customerName } = useFetchData({
    queryKey: ["customerNameOnHeader"],
    url: `/report/IncomingAlert/${
      currentOrder["IncomingAlert"]
    }/field?field=customerName&orderId=${encodeURIComponent(
      currentOrder.orderId
    )}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  const { data: inwardNumber } = useFetchData({
    queryKey: ["inwardNumberOnHeader"],
    url: `/order/${currentOrder.orderId}`,
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  const downloadPDF = async () => {
    setLoading(true); // Start loading
    const input = reportRef.current;

    if (!input) {
      console.error("Report content is not available");
      setLoading(false); // Stop loading if there's an error
      return;
    }

    // Capture the entire scrollable area of the component
    const canvas = await html2canvas(input, {
      scale: 1,
      useCORS: true,
      windowWidth: input.scrollWidth,
      windowHeight: input.scrollHeight,
    });

    // Define A4 dimensions in pixels
    const a4Width = 595; // A4 page width in jsPDF units (pt)
    const a4Height = 842; // A4 page height in jsPDF units (pt)

    // Calculate the scaled width to be 75% of A4 width
    const scaledWidth = a4Width * 0.75;
    const scaledHeight = (scaledWidth * canvas.height) / canvas.width;

    // Calculate the horizontal offset to center the content
    const offsetX = (a4Width - scaledWidth) / 2;
    const topOffset = 48; // Top offset in pixels

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "pt", "a4");
    let position = topOffset;
    let pageHeightLeft = canvas.height;

    // Add each page with centered and scaled content
    while (pageHeightLeft > 0) {
      pdf.addImage(
        imgData,
        "PNG",
        offsetX, // Centered horizontally
        position,
        scaledWidth, // Scaled width
        scaledHeight // Scaled height to maintain aspect ratio
      );
      pageHeightLeft -= a4Height;
      position -= a4Height;

      if (pageHeightLeft > 0) {
        pdf.addPage();
      }
    }

    pdf.save("report.pdf");
    setLoading(false); // Stop loading when done
  };

  return (
    <div className="reportPageContainer">
      <div className="reportHeader">
        <div className="reportHeaderTitle">
          <SectionHeader mark={false}>{lookup(currentReport)}</SectionHeader>
        </div>
        <div className="reportSubheader">
          <SectionSubHeader>
            Customer Name: {currentOrder ? currentOrder["customerName"] : ""}
          </SectionSubHeader>
          <SectionSubHeader>
            Inward Number: {inwardNumber ? currentOrder["inwardNumber"] : ""}
          </SectionSubHeader>
        </div>
        <div className="reportHeaderButtons">
          {/* <Button variant='outlined'>Generate Report</Button> */}
          <Button
            variant="outlined"
            disableElevation
            // onClick={handleSwitchReportClick}
            // endIcon={<KeyboardArrowDownIcon />}
            onClick={() => handleOpenReport()}
          >
            Switch Report
          </Button>
          {/* <Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
					>
						{reportData.map((report) => (
							<MenuItem
								key={report.label}
								onClick={() => handleOpenReport(report)}
							>
								{report.label}
							</MenuItem>
						))}
					</Menu> */}
          <Button variant="outlined" onClick={downloadPDF} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Download Report"}
          </Button>
        </div>
      </div>
      <div className="reportPage" ref={reportRef}>
        <GroupedReportFields handleClick={handleOpenField} />
      </div>
    </div>
  );
}

export default ReportPage;
