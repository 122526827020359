import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function SimpleCard({
  cardTitle,
  cardContent,
  cardActionOne,
  cardActionTwo,
  photoUrl,
  onActionOneClick,
  onActionTwoClick,
}) {
  return (
    <Card sx={{ maxWidth: 289 }}>
      {photoUrl && (
        <CardMedia sx={{ height: 140 }} image={photoUrl} title="green iguana" />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {cardTitle ? cardTitle : "no title"}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {cardContent}
        </Typography>
      </CardContent>
      <CardActions>
        {cardActionOne && (
          <Button size="small" onClick={onActionOneClick}>
            {cardActionOne}
          </Button>
        )}
        {cardActionTwo && (
          <Button size="small" onClick={onActionTwoClick}>
            {cardActionTwo}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
