import { Box, Button, Typography } from '@mui/material';
import useHelperFunctions from '../../helpers/functions';
import './orderDashboard.css';
import { Link } from 'react-router-dom';

function OrderDashboard() {
	const { currentOrder, navigate } = useHelperFunctions();
	console.log(currentOrder.qrCodeUrl);

	if (!currentOrder || JSON.stringify(currentOrder) === '{}') {
		return (
			<div className='dashboardHeader'>
				<Typography variant='h5'>
					Order not loaded. Please <Link to='/all-orders'>load</Link> or{' '}
					<Link to='/create-order'> create</Link> order.
				</Typography>
			</div>
		);
	}

	return (
		<Box>
			<div className='dashboardContainer'>
				<div className='dashboardHeader'>
					<Typography variant='h5'>
						Order Inward Number: {currentOrder.inwardNumber}
					</Typography>
					<Typography variant='body2' sx={{ color: 'lightgray' }}>
						Order Id: {currentOrder.orderId}
					</Typography>
				</div>

				<img
					src={currentOrder.qrCodeUrl}
					alt='new order'
					className='qrCodeImage'
					style={{ width: '50mm' }}
				/>
				<div className='dashboardButtonContainer'>
					<Button
						variant='outlined'
						onClick={() => navigate(`/${currentOrder.orderId}`)}
					>
						Update Report
					</Button>

					<Button variant='contained'>
						{' '}
						<a
							href={currentOrder ? currentOrder.qrCodeUrl : ''}
							target='_blank'
							rel='noopener noreferrer'
							style={{ textDecoration: 'none', color: 'inherit' }}
						>
							Download QR{' '}
						</a>{' '}
					</Button>
				</div>
				<Typography variant='body2' sx={{ marginTop: '16px' }}>
					Note: The image size is set to 50mm
				</Typography>
			</div>
		</Box>
	);
}

export default OrderDashboard;
