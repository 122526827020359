import {
  frontArrangement,
  frontDetails,
  frontQty,
  rearArrangement,
  rearDetails,
  rearQty,
  mechanicalSealDetails,
  mechanicalSealQty,
} from "./NewBearingComponents";

export const NewBearingReport = [
  {
    groupHeader: "Front ",
    components: [frontArrangement, frontDetails, frontQty],
  },
  {
    groupHeader: "Rear ",
    components: [rearArrangement, rearDetails, rearQty],
  },
  {
    groupHeader: "Mechanical Seal ",
    components: [mechanicalSealDetails, mechanicalSealQty],
  },
];
