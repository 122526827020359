import { Typography } from "@mui/material";
// import OrderAction from "./components/OrderAction";
// import OrdersTable from "./components/OrdersTable";
// import "./home.css";
import { Link } from "react-router-dom";
import SectionHeader from "../shared/typography/SectionHeader";
import MainForm from "../../experiments/DynaForm";
import AddCustomerForm from "../../config/AddCustomerForm";

function Customer() {
  return (
    <div>
      <SectionHeader> Add New Customer</SectionHeader>
      <AddCustomerForm />
    </div>
  );
}

export default Customer;
