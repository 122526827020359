import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./navbar.css";
import setco from "../../../assets/staticImages/setco.png";
import useHelperFunctions from "../../../helpers/functions";
import { Button } from "@mui/material";

function Navbar() {
  const {
    navigate,
    isSidebarOpen,
    showSidebar,
    hideSidebar,
    hideOutlet,
    isTabletOrBelow,
    removeUser,
  } = useHelperFunctions();

  function handleLogoClick() {
    navigate("/");
    hideOutlet();
  }

  function handleLogout() {
    try {
      localStorage.removeItem("at"); // Remove the token from localStorage
      removeUser();
      console.log("Logout successful: Token removed");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  return (
    <div className="navbar">
      {isTabletOrBelow && (
        <div className="menuIcon">
          {!isSidebarOpen && <MenuIcon onClick={showSidebar} />}
          {isSidebarOpen && <MenuOpenIcon onClick={hideSidebar} />}
        </div>
      )}
      <img
        className="setcoLogo"
        src={setco}
        alt="Setco India"
        onClick={handleLogoClick}
      />
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
}

export default Navbar;
