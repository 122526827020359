import { create } from "zustand";
import { persist } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const useUIStore = create(
  devtools(
    persist((set) => ({
      // Initial state
      isUser: false,
      isSidebarOpen: false,
      isOutletOpen: false,
      createdNewOrder: false,
      orderIndex: {},

      // Actions to update state
      toggleSidebar: () =>
        set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
      toggleOutlet: () =>
        set((state) => ({ isOutletOpen: !state.isOutletOpen })),

      showOutlet: () => set({ isOutletOpen: true }),
      hideOutlet: () => set({ isOutletOpen: false }),

      showSidebar: () => set({ isSidebarOpen: true }),
      hideSidebar: () => set({ isSidebarOpen: false }),

      setCreatedNewOrderTrue: () => set({ createdNewOrder: true }),
      setCreatedNewOrderFalse: () => set({ createdNewOrder: false }),

      setOrderIndex: (orderData) => set({ orderIndex: orderData }),
      clearOrderIndex: () => {
        set({ orderIndex: {} });
      },

      verifyUser: () => {
        console.log("verifying user");
        const token = localStorage.getItem("at");

        if (!token) {
          console.log("Token is missing");
          set(() => ({
            isUser: false,
          }));
          return;
        }

        try {
          const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
          const isValid = payload.exp > Date.now() / 1000; // Check expiry

          if (isValid) {
            console.log("Token is valid");
            set((state) => {
              if (!state.isUser) {
                return {
                  isUser: true,
                };
              }
              return state; // Do not update if already verified
            });
          } else {
            console.log("Token expired, removing the token");
            localStorage.removeItem("at"); // Remove invalid token
            set(() => ({
              isUser: false,
            }));
          }
        } catch (err) {
          console.error("Token verification failed:", err.message);
          localStorage.removeItem("at"); // Remove invalid token
          set(() => ({
            isUser: false,
          }));
        }
      },
      removeUser: () => {
        set({ isUser: false });
      },
    }))
  )
);

export default useUIStore;
