import React, { useState } from "react";
import {
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useFetchData } from "../../api/axios";
import SectionHeader from "../shared/typography/SectionHeader";
import CustomerModal from "./CustomerModa";

const CustomerTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for the selected customer
  const rowsPerPage = 10;

  const { data: customers } = useFetchData({
    queryKey: ["customers"],
    url: "/customer",
    options: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  // Filter customers based on the search query
  const filteredCustomers = customers?.data?.filter((customer) =>
    Object.values(customer).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Open the modal with the selected customer details
  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
  };

  // Close the modal
  const handleCloseModal = () => {
    setSelectedCustomer(null);
  };

  return (
    <Box sx={{ mt: 4, maxWidth: "1000px" }}>
      <SectionHeader s={{ mb: 4 }}>Customer List</SectionHeader>

      {/* Search Field */}
      <TextField
        fullWidth
        label="Search Customers"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
      />

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Customer City</TableCell>
              <TableCell>Customer State</TableCell>
              <TableCell>Customer Country</TableCell>
              <TableCell>Postal Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer, index) => (
                <Tooltip title="Click to view details" arrow key={customer._id}>
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => handleCustomerClick(customer)}
                  >
                    <TableCell>{customer.customerId}</TableCell>
                    {/* Customer Name clickable */}
                    <TableCell>{customer.customerName}</TableCell>
                    <TableCell>{customer.customerCity}</TableCell>
                    <TableCell>{customer.customerState}</TableCell>
                    <TableCell>{customer.customerCountry}</TableCell>
                    <TableCell>{customer.postalCode}</TableCell>
                  </TableRow>
                </Tooltip>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredCustomers?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10]} // Fixed to show 25 rows only
      />

      {/* Dialog/Modal */}
      {selectedCustomer && (
        <CustomerModal
          handleCloseModal={handleCloseModal}
          selectedCustomer={selectedCustomer}
        />
      )}
    </Box>
  );
};

export default CustomerTable;
