import {
  customerLocation,
  customerName,
  financeApproval,
  isCustomerInformedAboutOutstanding,
  isFirstTimeCustomer,
  isPaymentOutstanding,
  natureOfProblem,
  salesApproval,
  spindleDispatchOnlyAfterClearance,
  spindleMake,
  spindleMaxRPM,
  spindleModel,
  spindleReceivedDate,
  spindleSerialNumber,
  spindleType,
  taperSpecifications,
  whyDispatchWithoutClearance,
} from "./IncomingAlertComponent";

export const IncomingAlertReport = [
  // {
  // 	groupHeader: 'Customer Details',
  // 	components: [customerName, customerLocation],
  // },
  {
    groupHeader: "Customer payment Details",
    components: [
      isFirstTimeCustomer,
      isPaymentOutstanding,
      isCustomerInformedAboutOutstanding,
      spindleDispatchOnlyAfterClearance,
      whyDispatchWithoutClearance,
    ],
  },
  {
    groupHeader: "Spindle Details",
    components: [
      spindleMake,
      spindleSerialNumber,
      taperSpecifications,
      spindleMaxRPM,
      spindleReceivedDate,
      spindleType,
      natureOfProblem,
    ],
  },
  {
    groupHeader: "Approval Status",
    component: [financeApproval, salesApproval],
  },
];
