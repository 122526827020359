import { Box, Button, Container, Typography } from "@mui/material";
import SectionHeader from "../../../shared/typography/SectionHeader";
import SectionSubHeader from "../../../shared/typography/SectionSubHeader";
import InputInward from "./InputInward";
import useHelperFunctions from "../../../../helpers/functions";
import { useFetchData } from "../../../../api/axios";
import { useState } from "react";
import CustomerModal from "../../../customer/CustomerModa";

function CreateOrder() {
  const [customer, setCustomer] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const {
    createdNewOrder,
    currentOrder,
    navigate,
    clearOrderIndex,
    hideOutlet,
    setCreatedNewOrderFalse,
  } = useHelperFunctions();

  function handleChangeOrder() {
    clearOrderIndex();
    hideOutlet();
    setCreatedNewOrderFalse();
    navigate("/all-orders");
  }

  const { data: customerData } = useFetchData({
    queryKey: ["all-customers"],
    url: "/customer",
    optins: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: "always",
      staleTime: 30 * 60 * 1000,
    },
  });

  function onSelect() {
    console.log("selected");
  }

  function handleCloseModal() {
    setModal(false);
    setCustomer(null);
    setSelectedOption("");
  }

  return (
    <Container disableGutters>
      {!createdNewOrder ? (
        <SectionHeader>Create New Order</SectionHeader>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionHeader>
            {currentOrder
              ? `You are currently editing ${currentOrder.inwardNumber}`
              : "Something went wrong, please crate the order again."}
          </SectionHeader>
          <div style={{ display: "flex", gap: "16px", marginTop: "24px" }}>
            <Button variant="contained">
              {" "}
              <a
                href={currentOrder ? currentOrder.qrCodeUrl : ""}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Download QR{" "}
              </a>{" "}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(`/${currentOrder["orderId"]}`)}
            >
              Update Reports{" "}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleChangeOrder}
            >
              Change Order{" "}
            </Button>
          </div>
        </div>
      )}

      {!createdNewOrder && (
        <div className="formContainer">
          <SectionSubHeader>Enter Inward Number</SectionSubHeader>
          <InputInward
            customerData={customerData}
            onSelect={onSelect}
            setCustomer={setCustomer}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            customer={customer}
          />
        </div>
      )}
      {customer &&
        (!customer.customerContactEmail ||
          !customer.customerConactPerson ||
          !customer.customerConactPhone) && (
          <Box sx={{ mt: 4, display: "flex", gap: 2, alignItems: "baseline" }}>
            <Typography color="error">
              Customer contact details are missing
            </Typography>
            <Button variant="outlined" onClick={() => setModal(true)}>
              Update Details
            </Button>
          </Box>
        )}
      {modal && customer !== null && (
        <CustomerModal
          selectedCustomer={customer}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Container>
  );
}

export default CreateOrder;
