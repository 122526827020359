import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import setco from "../../assets/staticImages/setco.png";
import spintrack from "../../assets/staticImages/spintrack.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 10,
  },
  title: {
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#FF0000", // Red background for title
    color: "#FFFFFF",
    padding: 5,
    marginBottom: 10,
    marginTop: 10,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeader: {
    backgroundColor: "#87CEEB", // Light blue background for headers
    fontWeight: "bold",
  },
  tableCol: {
    width: "12.5%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol7: {
    width: "14.28%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCol3: {
    width: "33.33%", // Adjust column width for 8 columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCell: {
    fontSize: 8,
    textAlign: "center",
  },
  spacer: {
    marginTop: 30,
  },
});

function FinalInspectionReportDownload() {
  const data = [
    {
      srNo: 1,
      testName: "R/O Shaft Bore",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.005",
      units: "MM",
      remark: "",
    },
    {
      srNo: 2,
      testName: "R/O On Pulley Dia",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 3,
      testName: "Drawbar clamping force",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 4,
      testName: "Axial Float",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 5,
      testName: "R/O of shaft on stud",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 6,
      testName: "R/O of shaft on stud",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 7,
      testName: "R/O of shaft on Drawbar",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 8,
      testName: "R/O of shaft on Drawbar",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 9,
      testName: "Axial Play",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 10,
      testName: "Radial Play",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 11,
      testName: "Residual imbalance front",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
    {
      srNo: 12,
      testName: "Residual imbalance rear",
      measured: "",
      notes: "",
      lowerLimit: "0.000",
      upperLimit: "0.020",
      units: "MM",
      remark: "",
    },
  ];

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 15,
              marginTop: 15,
            }}
          >
            <View>
              <Image src={setco} style={{ height: "auto", width: "48px" }} />
            </View>
            <View style={{ display: "flex", alignItems: "center" }}>
              <Text style={{ fontSize: "14px" }}>
                Setco Spindles India Pvt Ltd
              </Text>
              <Text style={{ fontSize: "12px" }}>Final Inspection report</Text>
            </View>
            <View>
              <Image
                src={spintrack}
                style={{
                  height: "auto",
                  width: "48px",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              />
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: "10px",
            }}
          >
            <View>
              <Text>Customer Name:</Text>
            </View>
            <View>
              <Text>Date: December 12, 2024</Text>
            </View>
          </View>

          <View style={{ margin: "10px 0" }}>
            <View
              style={[
                styles.tableRow,
                styles.tableHeader,
                { borderLeftWidth: 1, borderTopWidth: 1 },
              ]}
            >
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Spindle Model</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Sr. No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Jo. No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}> </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Taper type</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>HSK-50</Text>
              </View>
            </View>
          </View>
          <Text style={styles.title}>FINAL INSPECTION REPORT</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Sr.No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Test Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Measured</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Notes</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Lower Limit</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Upper Limit</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Units</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Remark</Text>
              </View>
            </View>

            {/* Table Body */}
            {data.map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.srNo}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.testName}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.measured}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.notes}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.lowerLimit}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.upperLimit}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.units}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.remark}</Text>
                </View>
              </View>
            ))}
          </View>
          {/* testing and balancing */}
          <Text style={styles.title}>TESTING AND BALANCING REPORT</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Sr.No</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Trial Run</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>RPM</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Temp (Front)</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Temp (Rear)</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Time</Text>
              </View>
              <View style={styles.tableCol7}>
                <Text style={styles.tableCell}>Remark</Text>
              </View>
            </View>

            {/* Table Body */}
            {[
              {
                srNo: 1,
                trialRun: "Trial Run-1",
                rpm: "",
                tempFront: "",
                tempRear: "",
                time: "",
                remark: "",
              },
              {
                srNo: 2,
                trialRun: "Trial Run-2",
                rpm: "",
                tempFront: "",
                tempRear: "",
                time: "",
                remark: "",
              },
              {
                srNo: 3,
                trialRun: "Trial Run-3",
                rpm: "",
                tempFront: "",
                tempRear: "",
                time: "",
                remark: "",
              },
            ].map((row, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.srNo}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.trialRun}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.rpm}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.tempFront}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.tempRear}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.time}</Text>
                </View>
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell}>{row.remark}</Text>
                </View>
              </View>
            ))}
          </View>
          {/* Remark Section */}
          <View>
            <View
              style={{
                flex: 1,
                padding: 5,
                marginTop: "24px",
                marginBottom: "24px",
              }}
            >
              <Text style={[{ textAlign: "left", fontSize: "12px" }]}>
                Remark - Please warm up spindle at your end (at least 60min)
                before handover to production
              </Text>
            </View>
          </View>
          <Text style={styles.title}>Approved By</Text>
          <View style={[styles.spacer]} />
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Testing In-charge</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>Assembly In-charge</Text>
              </View>
              <View style={styles.tableCol3}>
                <Text style={styles.tableCell}>ISR Manager</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default FinalInspectionReportDownload;
