import React from "react";
import LoginForm from "./LoginForm";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import sectoImage from "../../assets/staticImages/setcoBanner.webp";
import spintrack from "../../assets/staticImages/spintrack.png";
import setcoLogo from "../../assets/staticImages/setco.png";
import { Box } from "@mui/material";

function LoginBlock({ hideSocial, setHideSocial }) {
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      username: data.get("username"),
      password: data.get("password"),
    });

    try {
      const res = await loginUser(data.get("username"), data.get("password"));
      console.log("User logged in successfully:", res);
      navigate("/");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on medium and larger
        height: "100vh", // Full viewport height
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          flexGrow: 2,
          display: { xs: "none", md: "block" }, // Hide on small screens
          backgroundImage: `url(${sectoImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      {/* Login Form Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "24px",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <img
              src={setcoLogo}
              alt=""
              style={{ width: "120px", height: "auto" }}
            />
            <img src={spintrack} alt="" width="200px" />
          </div>
          <LoginForm handleLogin={handleLogin} />
        </div>
      </Box>
    </Box>
  );
}

export default LoginBlock;

const loginUser = async (username, password) => {
  const BASE_URL = "https://spintrack.in";
  // const BASE_URL = "http://localhost:5000";

  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, {
      username,
      password,
    });

    console.log("Login successful:", response.data.data);
    localStorage.setItem("at", response.data.data);
    return response.data.data;
  } catch (error) {
    if (error.response) {
      console.error("Login failed:", error.response.data.message);
      throw new Error(error.response.data.message);
    } else {
      console.error("An unexpected error occurred:", error.message);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
};
